import { FormInstance, Modal, ModalProps, SelectProps } from 'antd';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import { languageOptions, STATUS } from 'config/constants';
import { EAge, EStatus } from 'types/enums';

import { HttpService } from './http.service';

export const filterOption = (input: string, option: any) => {
	const newInput = input.toLowerCase();

	const optionLabel = option?.label?.toString().toLowerCase();
	const optionValue = option?.value?.toString().toLowerCase();

	return optionValue?.includes(newInput) || optionLabel?.includes(newInput);
};

const createSlug = (value: string) => {
	return value
		.replace(/\s+/g, '-') // Replaces sequences of one or more whitespace characters with a single hyphen
		.toLowerCase()
		.replace(/[^\w-]+/g, '') // Removes all characters that are not alphanumeric, underscores, or hyphens
		.replace(/-+/g, '-') // Replaces sequences of one or more hyphens with a single hyphen
		.replace(/^-|-$/g, ''); // Removes leading and trailing hyphens
};

export const generateSlug = (form: FormInstance, controlsName: string[]) => {
	const value = controlsName.map((controlName) => form.getFieldValue(controlName) ?? '').join(' ');

	return createSlug(value);
};

export function isValidUrl(url: string) {
	const regex = /^(http:\/\/|https:\/\/)[^\s$.?#].[^\s]*$/i;
	return regex.test(url);
}

export const getUserStatusColor = (status: string) => {
	switch (status) {
		case 'Active':
			return '#008000';
		case 'Inactive':
			return '#808080';
		case 'Suspended':
			return '#FFA500';
		default:
			return '';
	}
};

export function handleFormError(formField: string, message: string, form: FormInstance) {
	form.setFields([
		{
			name: formField,
			errors: message ? [message] : ['']
		}
	]);
}

type TDeleteRecord = {
	deleteUrl?: string;
	modalProps?: ModalProps;
	onSuccess?: (data?: any) => void;
	onError?: (error?: any) => void;
	t?: TFunction;
};

// todo remove.
export const deleteRecord = async (params: TDeleteRecord) => {
	const { deleteUrl, modalProps, onSuccess, onError, t } = params;

	Modal.confirm({
		title: t ? t('modal.areYouSureToDelete') : 'Are you sure to delete?',
		okText: t ? t('modal.yes') : 'Yes',
		okType: 'danger',
		cancelText: t ? t('modal.no') : 'No',
		content: t ? t('modal.actionCannotBeUndone') : 'This action cannot be undone',
		onOk: async () => {
			try {
				if (deleteUrl) {
					const data = await HttpService.delete(deleteUrl);
					onSuccess?.(data);

					// return;
				}

				// await modalProps?.onOk?.(e: any);
			} catch (error) {
				onError?.(error);
			}
		},
		...modalProps
	});
};

export const getDisabledDate = (current: any, selectedDate: any) => {
	// If there are no dates selected or both dates are selected, disable dates before today
	if (!selectedDate || selectedDate.length !== 1) {
		return current && current < dayjs().startOf('day');
	}

	// If only the start date is selected, disable dates before the start date
	return current && current < dayjs(selectedDate[0]).startOf('day');
};

export const getDisabledTime = (current: any) => {
	const range = (start: number, end: number): number[] => {
		return Array(end - start + 1)
			.fill(0)
			.map((_, idx) => start + idx);
	};

	if (current && current.isSame(dayjs(), 'day')) {
		const currentHour = dayjs().hour();
		const currentMinute = dayjs().minute();

		return {
			disabledHours: () => range(0, currentHour - 1),
			disabledMinutes: () => range(0, currentMinute)
		};
	}
	return {
		disabledHours: () => range(0, -1),
		disabledMinutes: () => range(0, -1)
	};
};

const ageMap = new Map<string, string>(Object.entries(EAge).map(([key, value]) => [value, key]));

export const getAgeEnumKey = (ageValue: EAge | string): string | undefined => {
	if (typeof ageValue === 'string' && ageMap.has(ageValue)) {
		return ageMap.get(ageValue);
	}

	// If the age value is not a valid enum value, return custom value
	if (!ageValue) return '';
	const result = Number(ageValue) > 7 ? 'A' : 'UA';
	return result;
};

export const formatToUnits = (number: number) => {
	const abbrev = ['', 'K', 'M', 'B', 'T'];
	const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
	const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
	const suffix = abbrev[order];

	const scaledNumber = number / 10 ** (order * 3);
	const fixedNumber = scaledNumber.toFixed(1);
	const hasDecimalPart = fixedNumber.split('.')[1] !== '0';

	if (order === 0 || !hasDecimalPart) {
		return Math.round(scaledNumber) + suffix;
	}

	return fixedNumber + suffix;
};

export const nameParser = (firstName: string | undefined, lastName?: string | undefined) =>
	[firstName, lastName].filter((name) => typeof name === 'string' && name.trim()).join(' ');

export const getStatusDropdownListExceptArchived = (): SelectProps['options'] => {
	const filtered = STATUS.filter((status) => status.value !== EStatus.ARCHIVED);
	return filtered;
};

export const getStatusValuesExceptArchived = (): EStatus[] => {
	const filtered = STATUS.filter((status) => status.value !== EStatus.ARCHIVED);
	return filtered.map((status) => status.value);
};

export const disableFutureDates = (current: dayjs.Dayjs) => current && current > dayjs().endOf('day');
export const disableFutureTimesCurrentDate = (current: dayjs.Dayjs) => {
	if (!current) {
		return {};
	}
	const now = dayjs();
	if (current.isSame(now, 'day')) {
		return {
			disabledHours: () => Array.from({ length: 24 }, (_, i) => i).splice(now.hour() + 1, 24),
			disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).splice(now.minute() + 1, 60),
			disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).splice(now.second() + 1, 60)
		};
	}
	return {};
};

export const getLanguageLabel = (language: string) => {
	return languageOptions.find((e) => e.value === language)?.label || 'N/A';
};
